/* RESPONSIBLE TEAM: team-knowledge-interop */
import { type AsyncHasMany, attr, belongsTo, hasMany, type SyncHasMany } from '@ember-data/model';
import { type ContentState, State } from 'embercom/models/data/external-contents/constants';
import { EntityType } from './data/entity-types';
import type ContentFragment from './content-service/content-fragment';
import { State as OutboundState } from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import { MonolingualKnowledgeContentModel } from 'embercom/objects/knowledge-hub/knowledge-hub-content';
import { FinAvailability } from 'embercom/lib/ai-content-library/constants';
import type Folder from './content-service/folder';
import type FolderMembership from './content-service/folder-membership';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { type Taggable } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import type Tag from 'embercom/models/tag';
import type Admin from 'embercom/models/admin';
import { UPDATE_TAGGING_URL } from 'embercom/lib/knowledge-hub/constants';
import { put } from 'embercom/lib/ajax';
import { ContentWrapperType } from 'embercom/models/content-service/content-wrapper';

export default class FileSourceContent extends MonolingualKnowledgeContentModel {
  @service declare appService: any;
  @service declare intl: IntlService & { languageNameFromCode: (code: string) => string };
  @service declare notificationsService: any;
  @service declare knowledgeHubService: KnowledgeHubService;

  @attr('string') declare appId: string;
  @attr('string') declare markdown: string;
  @attr('number') declare state: State;
  @attr('number') declare contentState: ContentState;
  @attr('string') declare title: string | undefined;
  @attr('string') declare url: string;
  @attr('number') declare sourceType: EntityType;
  @attr('number') declare sourceId: number;
  @attr('date') declare createdAt: string;
  @attr('date') declare updatedAt: string;
  @attr('number') declare lastEditedById: number;
  @attr('string') declare languageCode: string;
  @attr('number') declare chatbotAvailability: number;
  @attr('number') declare copilotAvailability: number;
  @attr('array') declare aiContentSegmentIds: Array<number> | [];
  @belongsTo('content-service/folder', { async: true })
  declare folder?: Folder;
  @belongsTo('content-service/folder-membership', { async: false })
  declare folderMembership?: FolderMembership;
  @hasMany('tag', { async: true }) declare tags: AsyncHasMany<Tag>;
  @hasMany('tagging', { async: false }) declare taggings: SyncHasMany<any>;

  entityType: number = EntityType.FileSourceContent;

  async toggleState() {
    this.state = this.state === State.published ? State.unpublished : State.published;
    await this.save();

    if (this.associatedListItemFragment) {
      if (this.state === State.published) {
        this.associatedListItemFragment.state = OutboundState.Live;
      } else {
        this.associatedListItemFragment.state = OutboundState.Paused;
      }
    }
  }

  async updateLanguageCode(languageCode: string) {
    this.languageCode = languageCode;
    await taskFor(this.saveFileSourceContent).perform(
      this.intl.t('ai-content-library.file-content.language-update.success'),
      this.intl.t('ai-content-library.file-content.language-update.error'),
    );

    if (this.associatedListItemFragment) {
      this.associatedListItemFragment.locale = languageCode;
    }
  }

  @task *saveFileSourceContent(
    successMessage: string,
    failureMessage: string,
  ): TaskGenerator<void> {
    try {
      yield this.save();

      this.notificationsService.notifyConfirmation(successMessage);
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: failureMessage,
      });
    }
  }

  get associatedListItemFragment(): ContentFragment | undefined {
    return this.store.peekRecord(
      'content-service/content-fragment',
      `${EntityType.FileSourceContent}_${this.id}`,
    );
  }

  get isUsedByFin(): boolean {
    return Boolean(this.state === State.published);
  }

  get locale(): string {
    return this.languageCode;
  }

  set locale(value: string) {
    this.languageCode = value;
  }

  get language(): string | void {
    if (this.locale) {
      return this.intl.languageNameFromCode(this.locale);
    }
  }

  async delete() {
    let fileSource = await this.store.peekRecord('content-service/file-source', this.sourceId);
    await fileSource.destroyRecord();
    this.unloadRecord();
  }

  // start: KnowledgeHubContent / KnowledgeHubItemInterface

  get editorConfig() {
    let sidePanelConfig = [{ name: 'knowledge-hub/content-editor/pdf/data-section' }];

    if (this.appService.app.canUseExternalAi) {
      sidePanelConfig.push(
        { name: 'knowledge-hub/content-editor/shared/visibility-section' },
        { name: 'knowledge-hub/content-editor/shared/audience-section' },
      );
    }

    sidePanelConfig.push(
      { name: 'knowledge-hub/content-editor/shared/tags-section' },
      { name: 'knowledge-hub/content-editor/folder-section' },
    );

    return {
      viewMode: {
        headerConfig: { name: 'knowledge-hub/content-editor/pdf/view/header' },
        editorConfig: { name: 'knowledge-hub/content-editor/pdf/view/editor' },
        sidePanelConfig,
      },
      editMode: {
        headerConfig: { name: '' },
        editorConfig: { name: '' },
        sidePanelConfig: [],
      },
    };
  }

  get chatbotAvailabilityOption(): FinAvailability {
    return this.chatbotAvailability;
  }

  set chatbotAvailabilityOption(availability: FinAvailability) {
    this.chatbotAvailability = availability;
  }

  get copilotAvailabilityOption(): FinAvailability {
    return this.copilotAvailability;
  }

  set copilotAvailabilityOption(availability: FinAvailability) {
    this.copilotAvailability = availability;
  }

  get parentIdFromRelationship(): string | undefined {
    // @ts-ignore - TS doesn't like the 'folder' key here for some reason
    let folderRelationship = this.belongsTo('folder');
    return folderRelationship?.id();
  }

  get parent() {
    return this.folder;
  }

  set parent(parent: Folder | undefined) {
    this.folder = parent;
    this.onParentChange(parent);
  }

  get displayName(): string {
    return (
      this.title ||
      this.intl.t('ai-content-library.list.cells.title.untitled', {
        objectName: this.humanReadableContentName,
      })
    );
  }

  get isEditable() {
    return true;
  }

  get copilotAvailableValue(): FinAvailability {
    return FinAvailability.DERIVED;
  }

  get chatbotAvailableValue(): FinAvailability {
    return FinAvailability.DERIVED;
  }

  get latestVersion() {
    return this;
  }

  get localization() {
    return this;
  }

  get versionTitle(): string | undefined {
    return this.title;
  }

  set versionTitle(value: string | undefined) {
    this.title = value;
  }

  get updaterId(): number | undefined {
    return this.lastEditedById;
  }

  get taggable(): Taggable | undefined {
    return {
      tags: this.tags,
      taggings: this.taggings,
      type: 'file-source-content',
      updateTaggings: async (
        _admin: Admin,
        addedTags: Tag[],
        removedTags: Tag[],
        _initialTags: Tag[],
      ) => {
        return await this.updateTaggings(addedTags, removedTags);
      },
    };
  }

  // end: KnowledgeHubContent / KnowledgeHubItemInterface

  async updateTaggings(addedTags: Tag[], removedTags: Tag[]) {
    await put(UPDATE_TAGGING_URL, {
      app_id: this.app.id,
      added_tag_ids: addedTags.map((tag) => tag.id),
      removed_tag_ids: removedTags.map((tag) => tag.id),
      content_wrapper_id: this.id,
      content_wrapper_type: ContentWrapperType.SUPPORT_CONTENT,
      entity_type: EntityType.FileSourceContent,
      entity_id: this.id,
    });
  }
}
